export const USE_LOCAL_MAP_FILE = false;
export const DEFAULT_ENV = 'grid';

export const START_POINT_X = -100;
export const START_POINT_Y = 0;
export const START_POINT_Z = 10;

export const SKYBOX_OFFSET_X = 100;
export const SKYBOX_OFFSET_Y = 0;
export const SKYBOX_OFFSET_Z = 20;

export const GUI_TEXT_SIZE = 100;
export const GUI_SCALE_PORTRAIT = 3;
export const GUI_SCALE_LANDSCAPE = 1.4;

export const ENABLE_LIGHTS = false;